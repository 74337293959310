import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getTopUsersActiveId,
  getTopUsersTabsState,
  setTopActiveUserId,
  updateRequestedListPage
} from 'store/slices/filtersSlice';

import { UserAva, ValueWithTooltip } from '../../../../../components';
import BlurredSpinner from '../../../../../components/BluresSpinner';
import { FileIcon } from '../../../../../components/SvgComponents';
import { Datasource, UserAvaType, UserFilter } from '../../../../../types';
import { getDateSourceIcon } from '../../../../../utils';
import styles from './styles.module.scss';

type UserItemProps = {
  data: {
    _id: string;
    displayName: string;
    image: string;
    followers: number;
    isBot: boolean;
    posts: number;
    profileUrl: string;
    dataSource: Datasource;
    userName: string;
    impression: number;
  };
  index: number;
  loading: boolean;
};

const RobotIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 6.66665V3.33331H6.66667"
      stroke="#F04438"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 6.66669H4.99999C4.07952 6.66669 3.33333 7.41288 3.33333 8.33335V15C3.33333 15.9205 4.07952 16.6667 4.99999 16.6667H15C15.9205 16.6667 16.6667 15.9205 16.6667 15V8.33335C16.6667 7.41288 15.9205 6.66669 15 6.66669Z"
      stroke="#F04438"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66667 11.6666H3.33334M16.6667 11.6666H18.3333M12.5 10.8333V12.5M7.5 10.8333V12.5"
      stroke="#F04438"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const emptyImageConfig: Record<string, string> = {
  [Datasource.Reddit]: '/images/reddit-default-avatar.png'
};

const UserItem: React.FC<UserItemProps> = ({ data, index = 0, loading }) => {
  const { _id, displayName, image, followers, posts, dataSource, userName, isBot, impression, profileUrl } = data;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const Icon = getDateSourceIcon(dataSource);
  const { value } = useAppSelector(getTopUsersTabsState);
  const activeUserId = useAppSelector(getTopUsersActiveId);

  const isFollowersTabs = value === UserFilter.followers;

  const onUserClick = () => {
    if (loading) {
      return;
    }

    dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'postFilters' }));
    dispatch(setTopActiveUserId(_id !== activeUserId ? _id : null));
  };

  return (
    <div
      className={classNames(styles.wrapper, { [styles.border]: loading, [styles.active]: activeUserId === _id })}
      onClick={onUserClick}
    >
      <div className={classNames(styles.index, { [styles.yellow]: index === 0 })}>{index + 1}</div>
      <div className={styles.row}>
        <div className={styles.userHeading}>
          <UserAva
            type={UserAvaType.topUser}
            data={{
              name: displayName,
              src: image || emptyImageConfig[dataSource] || '',
              bottomLabel: (
                <a
                  className={styles.outerLink}
                  href={profileUrl}
                  target="blank"
                  rel="noopener"
                  onClick={(e: any) => e.stopPropagation()}
                >
                  {userName}
                </a>
              )
            }}
            withCrown={index === 0}
            size={40}
          />
          {isBot && (
            <Tooltip title={t('users.suspectBot')} placement="right">
              {RobotIcon}
            </Tooltip>
          )}
        </div>
        <div className={styles.labels}>
          {!!posts && (
            <div className={styles.flex}>
              <FileIcon style={{ marginTop: -1 }} />
              <span className={styles.label}>
                <ValueWithTooltip value={posts} />
              </span>
            </div>
          )}
          <div className={styles.flex}>
            <Icon />
            <span className={styles.label}>
              <ValueWithTooltip value={isFollowersTabs ? followers || 0 : impression || 0} />
            </span>
          </div>
        </div>
      </div>
      {loading && <BlurredSpinner size={32} fillContent={true} />}
    </div>
  );
};

export default UserItem;
